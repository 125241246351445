import { notification } from 'antd';

const showError = (message: string, title: string = '提示信息') => {
    notification.error({
        message: title,
        description: message,
    });
};

const showWarning = (message: string, title: string = '提示信息') => {
    notification.warning({
        message: title,
        description: message,
    });
};

const showInfo = (message: string, title: string = '提示信息') => {
    notification.info({
        message: title,
        description: message,
    });
};

const showSuccess = (message: string, title: string = '提示信息') => {
    notification.success({
        message: title,
        description: message,
    });
};

export default {
    showError,
    showWarning,
    showInfo,
    showSuccess,
};

// 导入axios
import axios from 'axios';
import { aesDecode } from './cryptoUtil';
// import AppMessage from '@/utils/AppMessage'

const BASE_URL = 'https://api.aijijiang.com';
const exportSecret = '46EBA22EF5204DD5B110A1F730513965';

export interface IRequestResult<T> {
    code?: number;
    msg?: string;
    data: T;
    status: number;
}

// 基地址
const service = axios.create({
    baseURL: '',
    timeout: 10000,
});

// 请求拦截
service.interceptors.request.use(
    (config) => {
        config.headers['app-version'] = '1.0.0';
        config.headers['os'] = 'pc';
        config.headers['os-version'] = '1.0.0';
        config.headers['Referrer-Policy'] = 'no-referrer';

        config.headers.token = localStorage.getItem('token');
        config.headers.token2 = localStorage.getItem('token2');
        config.headers.userId = localStorage.getItem('userId');

        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);
// 响应拦截
service.interceptors.response.use(
    async (res) => {
        // 凭证验失败
        // console.log('----拦截响应数据 是否加密', import.meta.env.VITE_RESPONSE_DATA_CIPHERED)
        // console.log('VITE_RESPONSE_DATA_CIPHERED = ', typeof import.meta.env.VITE_RESPONSE_DATA_CIPHERED)
        // console.log('----拦截响应数据', res.data)
        // 解密
        // if (import.meta.env.VITE_RESPONSE_DATA_CIPHERED === 'true') {
        const decodeRes: any = aesDecode(res.data, exportSecret);
        if (decodeRes && JSON.parse(decodeRes).success) {
            res.data = JSON.parse(decodeRes.data);
            console.log('----拦截响应数据 解密后', res.data);
        } else {
            // AppMessage.showError('数据解密')
        }
        // }
        if (res.data.code === 10003) {
            localStorage.clear();
        }
        console.log('---res.data=', res.data);
        return res.data;
    },
    // 响应错误的代码写这里
    (error) => {
        return Promise.reject(error);
    }
);

export const sendPost = async <T>(url: string, data?: unknown): Promise<IRequestResult<T>> => {
    const response: IRequestResult<T> = await service({ method: 'post', url, data });
    return response;
};
export const sendGet = async <T>(url: string, params?: unknown): Promise<IRequestResult<T>> => {
    const response: IRequestResult<T> = await service({ method: 'get', url, params });
    return response;
};
// 本地文件上传，post请求，去提交表单（contentType: application/form-data）
export const uploadLocal = async <T>(
    url: string,
    file: any,
    params?: any
): Promise<IRequestResult<T>> => {
    const data = new FormData();
    data.append('file', file);
    Object.entries(params).forEach(([key, value]) => {
        data.append(key, typeof value === 'string' ? value : JSON.stringify(value));
    });
    const response: IRequestResult<T> = await axios.post(url, data);
    return response;
};

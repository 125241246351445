import React from 'react';
import { HashRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import NotFound from './components/pages/NotFound';
import Login from './components/pages/Login';
import App from './App';

// import { ConfigProvider } from 'antd'
// import { RouterProvider } from 'react-router-dom'
// import router from './router/router'

export default () => (
    // <ConfigProvider>
    //     <RouterProvider router={router} />
    // </ConfigProvider>

    <Router>
        <Switch>
            <Route exact path="/" render={() => <Redirect to="/app/dashboard/index" push />} />
            <Route path="/app" component={App} />
            <Route path="/404" component={NotFound} />
            <Route path="/login" component={Login} />
            <Route component={NotFound} />
        </Switch>
    </Router>
);

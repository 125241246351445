/**
 * Created by qianshi on 2024/6/15.
 */
import React, { useState, useEffect } from 'react';
import { Row, Col, Card } from 'antd';
import { Table, Button, Modal, Form, Input, Select } from 'antd';
import { queryUserManager, setRadio, userManagerDetail } from '../../api/api';
import moment from 'moment';
import AppMessage from '../../utils/AppMessage';
import './index.less';

type UserManagerProps = {};

// const exportSecret = "46EBA22EF5204DD5B110A1F730513965"

const detailObj: any = {
    id: 'ID',
    userName: '达人账号名称',
    registeDate: '注册时间',
    phone: '电话号码',
    monthlyAcquisition: '月拉新量',
    monthlyAcquisitionOld: '月拉旧量',
    monthlyIncome: '月总收益',
    masterUserId: '师傅ID',
};

const buttonItemLayout = { wrapperCol: { span: 14, offset: 4 } };

const UserManager = (props: UserManagerProps) => {
    const [showForm, setShowForm] = useState<boolean>(false);
    const [tableLoading, setTableLoading] = useState<boolean>(false);
    const [withdrawalData, setUserManagerData] = useState<any>([]);
    const [confirmId, setConfirmId] = useState<any>(''); // 确认ID
    const [detail, setDetail] = useState<any>({}); // 详情信息
    const [uploadUrl, setUploadUrl] = useState<any>(''); // 上传的图片

    const [pageInfo, setPageInfo] = useState<any>({
        pageIndex: 1,
        pageSize: 10,
        total: 0,
    });

    const [form] = Form.useForm();
    const [form2] = Form.useForm();

    const columns: any = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            width: 100,
            fixed: true,
        },
        {
            title: '达人名称',
            dataIndex: 'userName',
            key: 'userName',
            width: 150,
            fixed: true,
        },
        {
            title: '注册时间',
            dataIndex: 'registeDate',
            key: 'registeDate',
            width: 150,
            render: (val: any) => {
                return val ? moment(val)?.format('YYYY-MM-DD HH:mm:ss') : '-';
            },
        },
        {
            title: '电话',
            dataIndex: 'phone',
            key: 'phone',
            width: 150,
        },
        {
            title: '师傅ID',
            dataIndex: 'masterUserId',
            key: 'masterUserId',
            width: 100,
        },
        {
            title: '师傅名称',
            dataIndex: 'masterUserName',
            key: 'masterUserName',
            width: 100,
        },

        {
            title: '拉旧扣单比例',
            dataIndex: 'deductionOldRatio',
            key: 'deductionOldRatio',
            width: 100,
        },
        {
            title: '拉新扣单比例',
            dataIndex: 'deductionRatio',
            key: 'deductionRatio',
            width: 100,
        },

        {
            title: '邀请码',
            dataIndex: 'inviteCode',
            key: 'inviteCode',
            width: 100,
        },
        {
            title: '达人账号名称',
            dataIndex: 'mediaAccountName',
            key: 'mediaAccountName',
            width: 150,
        },
        {
            title: '总拉新量',
            dataIndex: 'totalAcquisition',
            key: 'totalAcquisition',
            width: 100,
        },
        {
            title: '总拉旧量',
            dataIndex: 'totalAcquisitionOld',
            key: 'totalAcquisitionOld',
            width: 100,
        },
        {
            title: '总推广收益',
            dataIndex: 'totalGrantIncome',
            key: 'totalGrantIncome',
            width: 100,
        },
        {
            title: '总队长收益',
            dataIndex: 'totalTlIncome',
            key: 'totalTlIncome',
            width: 100,
        },
        {
            title: '总收益',
            dataIndex: 'totalIncome',
            key: 'totalIncome',
            width: 100,
        },
        {
            title: '操作',
            dataIndex: 'option',
            key: 'option',
            width: 150,
            render: (val: any, record: any) => {
                return (
                    <>
                        <Button
                            type="link"
                            onClick={() => {
                                userManagerDetail({ id: record.id }).then((res: any) => {
                                    if (res.success) {
                                        const resData = {
                                            ...res?.data,
                                            registeDate: res?.data.registeDate
                                                ? moment(res?.data.registeDate).format(
                                                      'YYYY-MM-DD HH:mm:ss'
                                                  )
                                                : '',
                                        };
                                        setDetail(resData);
                                        form.setFieldsValue(resData);
                                        setShowForm(true);
                                        setConfirmId(record.id);
                                        setUploadUrl(res.data.images?.[0] || '');
                                    }
                                });
                            }}
                        >
                            设置扣单比例
                        </Button>
                    </>
                );
            },
            fixed: 'right',
        },
    ];

    useEffect(() => {
        queryUserManagerList({
            pageIndex: pageInfo.current,
            pageSize: pageInfo.pageSize,
        });
    }, []);

    const queryUserManagerList = (param: any) => {
        setTableLoading(true);
        queryUserManager({ ...param })
            .then((res: any) => {
                if (res.success) {
                    setUserManagerData(res.data);
                    setPageInfo({
                        ...pageInfo,
                        total: res.totalCount,
                    });
                }
            })
            .finally(() => {
                setTableLoading(false);
            });
    };

    const queryList = () => {
        const param = form2.getFieldsValue();
        queryUserManagerList({ ...param, ...pageInfo });
    };
    const handleTableChange = (pagination: any, filters: any, sorter: any) => {
        setPageInfo({
            pageIndex: pagination.current,
            pageSize: pagination.pageSize,
            total: pagination.total,
            filters,
            ...sorter,
        });
        const param = form2.getFieldsValue();
        queryUserManagerList({
            ...param,
            ...{
                pageIndex: pagination.current,
                pageSize: pagination.pageSize,
            },
        });
    };
    const confirm = () => {
        const paramForm = form.getFieldsValue();
        const param = {
            userId: paramForm.id, //达人账号id
            masterUserId: paramForm.masterUserId, //师傅id
            ratio: parseInt(paramForm.ratio), //比例
            type: parseInt(paramForm.type), //设置类型（1-拉新 2-拉旧）
        };
        setRadio(param).then((res: any) => {
            if (res.success) {
                AppMessage.showInfo('设置成功');
                setShowForm(false);
                setDetail({});
                setConfirmId('');
                setUploadUrl('');
                queryUserManagerList({});
            }
        });
    };
    return (
        <div className="gutter-example button-demo">
            <Row gutter={16}>
                <Col className="gutter-row" md={24}>
                    <div className="gutter-box">
                        <Card bordered={false}>
                            <Form
                                className={'keyword_search'}
                                layout="inline"
                                form={form2}
                                name="form_in_modal"
                                initialValues={{}}
                            >
                                <Form.Item name="userNo" label="达人账号名称">
                                    <Input />
                                </Form.Item>
                                <Form.Item name="mediaAccount" label="达人媒体账号名称">
                                    <Input />
                                </Form.Item>
                                <Form.Item name="phone" label="电话号码">
                                    <Input />
                                </Form.Item>
                                <Form.Item {...buttonItemLayout}>
                                    <Button type="primary" onClick={() => queryList()}>
                                        Submit
                                    </Button>
                                </Form.Item>
                            </Form>
                        </Card>
                        <Card bordered={false}>
                            <Table
                                loading={tableLoading}
                                onChange={handleTableChange}
                                columns={columns}
                                dataSource={withdrawalData}
                                scroll={{ x: 800 }}
                                pagination={{ ...pageInfo }}
                            />
                        </Card>
                    </div>
                </Col>
            </Row>
            {showForm && (
                <Modal
                    visible={showForm}
                    title="详细信息"
                    okText="提交"
                    onCancel={() => {
                        setShowForm(false);
                        setDetail({});
                        setConfirmId('');
                        setUploadUrl('');
                        form.setFieldsValue({ url: '' });
                    }}
                    onOk={() => confirm()}
                >
                    <Form
                        form={form}
                        name="form_in_modal"
                        {...{ labelCol: { span: 7 }, wrapperCol: { span: 16 } }}
                    >
                        {Object.keys(detail).map((i) => {
                            if (i === 'images') {
                                return;
                            }
                            return (
                                <Form.Item name={i} label={detailObj[i]}>
                                    <Input disabled />
                                </Form.Item>
                            );
                        })}
                        <Form.Item name={'ratio'} label="比例">
                            <Input placeholder="请设置1-100" />
                        </Form.Item>
                        <Form.Item name={'type'} label="设置类型">
                            <Select>
                                <Select.Option value="1">拉新</Select.Option>
                                <Select.Option value="2">拉旧</Select.Option>
                            </Select>
                        </Form.Item>
                    </Form>
                </Modal>
            )}
        </div>
    );
};

export default UserManager;

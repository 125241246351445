// import crypto from 'crypto'
import AES from 'crypto-js/aes';
import CryptoJS from 'crypto-js';

// https://www.npmjs.com/package/crypto-js

export const aesDecode = (ciphertext: string, key: string) => {
    const bytes = AES.decrypt(ciphertext, CryptoJS.enc.Utf8.parse(key), {
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7,
    });

    const originalText = bytes.toString(CryptoJS.enc.Utf8);
    return originalText;
};

// 加密
export const aesEncode = (text: string, key: string) => {
    // CryptoJS.AES.encrypt('my message', 'secret key 123').toString();
    return AES.encrypt(text, CryptoJS.enc.Utf8.parse(key), {
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7,
    }).toString();
};
